var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',{directives:[{name:"can",rawName:"v-can",value:('products.view'),expression:"'products.view'"}]},[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('iq-card',{scopedSlots:_vm._u([{key:"headerTitle",fn:function(){return [_c('h4',{staticClass:"card-title"},[_vm._v("Listado De Productos")])]},proxy:true},{key:"headerAction",fn:function(){return [_c('b-button',{directives:[{name:"can",rawName:"v-can",value:('products.create'),expression:"'products.create'"}],attrs:{"variant":"primary","to":{ name: 'products.register' }}},[_vm._v("Agregar Nuevo")])]},proxy:true},{key:"body",fn:function(){return [_c('b-row',[_c('b-col',{staticClass:"my-3",attrs:{"lg":"6"}},[_c('b-form-group',{staticClass:"mb-0",attrs:{"label":"Buscar:","label-for":"filter-input"}},[_c('b-input-group',[_c('b-form-input',{attrs:{"id":"filter-input","disabled":!Array.isArray(_vm.getProducts) ||
                      (_vm.getProducts.length === 0 && _vm.isBusy === false),"type":"search","placeholder":"Buscar productos"},model:{value:(_vm.filter),callback:function ($$v) {_vm.filter=$$v},expression:"filter"}})],1)],1)],1),_c('b-col',{staticClass:"table-responsive",attrs:{"md":"12"}},[_c('b-table',{attrs:{"hover":"","busy":!Array.isArray(_vm.getProducts) || _vm.getProducts.length === 0
                    ? true
                    : false || _vm.isBusy == true,"items":_vm.getProducts,"filter":_vm.filter,"fields":_vm.columns,"per-page":_vm.perPage,"current-page":_vm.currentPage},on:{"filtered":_vm.onFiltered},scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center text-danger my-2"},[(_vm.isBusy)?_c('div',[_c('b-spinner',{staticClass:"align-middle",attrs:{"type":"grow"}}),_c('strong',[_vm._v(" Cargando datos...")])],1):_c('span',{staticClass:"h5"},[_vm._v("Sin registros")])])]},proxy:true},{key:"cell(category)",fn:function(data){return _vm._l((data.item.categories),function(item,index){return _c('b-badge',{key:index,staticClass:"mr-1",attrs:{"variant":"primary"}},[_vm._v(" "+_vm._s(item.name)+" ")])})}},{key:"cell(option)",fn:function(data){return [_c('div',{directives:[{name:"can",rawName:"v-can",value:('products.view'),expression:"'products.view'"}]},[_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"title":"Ver detales","variant":" iq-bg-primary mr-1","size":"sm","to":{
                        name: 'products.show',
                        params: { id: data.item.id },
                      }}},[_c('i',{staticClass:"ri-eye-line ml-1"})])],1),_c('div',{directives:[{name:"can",rawName:"v-can",value:('products.update'),expression:"'products.update'"}]},[_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"title":"Editar","variant":" iq-bg-warning mr-1","size":"sm","to":{
                        name: 'products.edit',
                        params: { id: data.item.id },
                      }}},[_c('i',{staticClass:"ri-ball-pen-fill ml-1"})])],1),_c('div',{directives:[{name:"can",rawName:"v-can",value:('products.delete'),expression:"'products.delete'"}]},[_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"title":"Eliminar","variant":" iq-bg-danger","size":"sm"},on:{"click":function($event){return _vm.showMsgBoxTwo(data.item)}}},[_c('i',{staticClass:"ri-delete-bin-line ml-1"})])],1)]}}])}),_c('b-pagination',{staticClass:"mt-3",attrs:{"total-rows":_vm.totalRows,"per-page":_vm.perPage,"first-number":"","pills":"","size":"sm","align":"center"},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)],1)]},proxy:true}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <b-container v-can="'products.view'">
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">Listado De Productos</h4>
          </template>
          <template v-slot:headerAction>
            <b-button
              variant="primary"
              :to="{ name: 'products.register' }"
              v-can="'products.create'"
              >Agregar Nuevo</b-button
            >
          </template>
          <template v-slot:body>
            <b-row>
              <b-col lg="6" class="my-3">
                <b-form-group
                  label="Buscar:"
                  label-for="filter-input"
                  class="mb-0"
                >
                  <b-input-group>
                    <b-form-input
                      id="filter-input"
                      :disabled="
                        !Array.isArray(getProducts) ||
                        (getProducts.length === 0 && isBusy === false)
                      "
                      v-model="filter"
                      type="search"
                      placeholder="Buscar productos"
                    ></b-form-input>
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col md="12" class="table-responsive">
                <b-table
                  hover
                  :busy="
                    !Array.isArray(getProducts) || getProducts.length === 0
                      ? true
                      : false || isBusy == true
                  "
                  :items="getProducts"
                  :filter="filter"
                  :fields="columns"
                  :per-page="perPage"
                  :current-page="currentPage"
                  @filtered="onFiltered"
                >
                  <template #table-busy>
                    <div class="text-center text-danger my-2">
                      <div v-if="isBusy">
                        <b-spinner type="grow" class="align-middle"></b-spinner>
                        <strong> Cargando datos...</strong>
                      </div>
                      <span class="h5" v-else>Sin registros</span>
                    </div>
                  </template>
                  <template v-slot:cell(category)="data">
                    <b-badge
                    class="mr-1"
                      v-for="(item, index) in data.item.categories"
                      :key="index"
                      variant="primary"
                    >
                      {{ item.name }}
                    </b-badge>
                  </template>

                  <template v-slot:cell(option)="data">
                    <div v-can="'products.view'">
                      <b-button
                        v-b-tooltip.hover
                        title="Ver detales"
                        variant=" iq-bg-primary mr-1"
                        size="sm"
                        :to="{
                          name: 'products.show',
                          params: { id: data.item.id },
                        }"
                        ><i class="ri-eye-line ml-1"></i>
                      </b-button>
                    </div>
                    <div v-can="'products.update'">
                      <b-button
                        v-b-tooltip.hover
                        title="Editar"
                        variant=" iq-bg-warning mr-1"
                        size="sm"
                        :to="{
                          name: 'products.edit',
                          params: { id: data.item.id },
                        }"
                        ><i class="ri-ball-pen-fill ml-1"></i
                      ></b-button>
                    </div>
                    <div v-can="'products.delete'">
                      <b-button
                        v-b-tooltip.hover
                        title="Eliminar"
                        variant=" iq-bg-danger"
                        size="sm"
                        @click="showMsgBoxTwo(data.item)"
                        ><i class="ri-delete-bin-line ml-1"></i
                      ></b-button>
                    </div>
                  </template>
                </b-table>
                <b-pagination
                  class="mt-3"
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  first-number
                  pills
                  size="sm"
                  align="center"
                ></b-pagination>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from "@/config/pluginInit";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "ListProductos",
  mounted() {
    core.index();
  },
  async created() {
    this.$store.commit("SET_BREADCRUMB", this.items);
    this.isBusy = true;
    await this.listProducts({ search: null });
    this.isBusy = false;
    this.totalRows = this.getProducts.length
  },
  data() {
    return {
      columns: [
        { label: "SKU", key: "sku", class: "text-center" },
        { label: "Nombre", key: "name", class: "text-center" },
        { label: "Categoría", key: "category", class: "text-center" },
        { label: "Acciones", key: "option", class: "text-center" },
      ],
      perPage: 10,
      currentPage: 1,
      totalRows: 0,
      items: [
        {
          html: 'Inicio',
          to: "/",
        },
        {
          text: "Listado De Productos",
          active: true,
        },
      ],
      filter: null,
      isBusy: false,
    };
  },
  methods: {
    default() {
      return {};
    },
    ...mapActions({
      listProducts: "products/listProducts",
      destroyProducts: "products/destroyProducts",
    }),
    showMsgBoxTwo(item) {
      this.boxTwo = "";
      this.$bvModal
        .msgBoxConfirm("¿Desea eliminar este registro?.", {
          title: "Confirmación",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "Si",
          cancelTitle: "No",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          value ? this.remove(item) : "";
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async remove(item) {
      try {
        await this.destroyProducts(item.id);
        core.showSnackbar("success", "Registro eliminado");
      } catch (error) {
        const errors = error.response.data.errors;
        for (const key in errors) {
          core.showSnackbar("error", errors[key][0]);
          return false;
        }
      }
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
  computed: {
    lengt() {
      return this.getProducts.length;
    },
    ...mapGetters({
      getProducts: "products/getProducts",
    }),
  },
};
</script>
<style>
</style>
